<template>
  <div class="preview-container">
    <img
      v-if="preview"
      :src="preview"
      alt="Preview"
      class="preview"
      :class="{ 'portrait': label && label.portrait_mode, 'invalid': !labelValid }"
    >
    <div
      v-else
      class="no-preview"
      :class="{ 'invalid': !labelValid }"
    ></div>
  </div>
</template>

<script>
import {
  getLabelPrinter,
  validateLabel,
} from '@/helpers';

const _ = require('lodash');

export default {
  name: 'LabelPreview',
  components: {},
  props: {
    label: Object,
    validate: {
      type: Boolean,
      default: true,
    },
    previewEndpoint: {
      type: String,
      default: '/label_preview',
    },
  },
  computed: {
    labelValid() {
      if (!this.validate) return true;
      return validateLabel(this.label);
    },
  },
  data() {
    return {
      preview: null,
      schedulePreviewLabel: _.debounce(() => {
        this.previewLabel();
      }, 250),
    };
  },
  methods: {
    previewLabel() {
      if (!this.labelValid) return;
      this.$http
        .post(this.previewEndpoint)
        .send({ printer_id: getLabelPrinter() })
        .send({ label: this.label })
        .then((res) => {
          this.preview = `data:image/jpeg;base64,${res.text}`;
        })
        .catch(() => {
          this.preview = 'data:image/jpeg;base64,';
        });
    },
  },
  watch: {
    label: {
      deep: true,
      immediate: true,
      handler() {
        if (this.label === null) {
          this.preview = null;
          return;
        }
        this.schedulePreviewLabel();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .preview-container {
    max-width: 300px;
  }
  .preview {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 15px;
    width: 100%;
    height: auto;
    max-height: 150px;
  }
  .preview.portrait {
    max-height: 200px;
  }
  .preview.invalid {
    border-color: red;
  }
  .no-preview {
    display: inline-block;
    height: 150px;
    aspect-ratio: 16 / 9;
    border: 1px solid #ccc;
    background-color: white;
  }
  .no-preview.invalid {
    border-color: red;
  }
</style>
